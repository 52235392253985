import { POSITIVE_INT_PATTERN } from './constants';

// TODO: Generalize as we introduce additional getters for query params
export const getQueryParamAsPositiveInt = (
  value: string | string[] | undefined,
  defaultValue: number,
): number => {
  // Blank value
  if (value == null) {
    return defaultValue;
  }

  // Array value
  if (Array.isArray(value)) {
    return defaultValue;
  }

  // Not positive integer
  if (!POSITIVE_INT_PATTERN.test(value)) {
    return defaultValue;
  }

  return parseInt(value);
};

const toBoolean = (val: string): Nullable<boolean> =>
  val === 'true' ? true : val === 'false' ? false : null;

export const parseQs = (url: string) => {
  const parsedParams: Record<string, number | boolean | string> = {};

  const qs = url.split('?')[1];
  new URLSearchParams(qs).forEach((val, key) => {
    parsedParams[key] = !isNaN(parseInt(val))
      ? parseInt(val)
      : (toBoolean(val) ?? val);
  });

  return parsedParams;
};
